import React, { useMemo } from 'react';
import { useFormat } from 'helpers/hooks/useFormat';
import { useProductList } from '../../context';

interface Props {
  close?: () => void;
  isLoadingState?: {
    value: { [key: string]: boolean };
    set: React.Dispatch<React.SetStateAction<{ [key: string]: boolean }>>;
  };
  label?: string;
}

const SortFacet: React.FC<Props> = ({ close, isLoadingState, label }) => {
  const { formatMessage: formatProductMessage } = useFormat({ name: 'product' });

  const { replaceSort, activeSort } = useProductList();

  const options = useMemo<Array<{ label: string; attribute: string; value: 'asc' | 'desc' }>>(() => {
    return [
      {
        label: formatProductMessage({ id: 'priceAsc', defaultMessage: 'Price asc' }),
        attribute: 'price',
        value: 'asc',
      },
      {
        label: formatProductMessage({ id: 'priceDesc', defaultMessage: 'Price desc' }),
        attribute: 'price',
        value: 'desc',
      },
      {
        label: formatProductMessage({ id: 'best-selling', defaultMessage: 'Best-Selling' }),
        attribute: 'reviewRatingStatistics.highestRating',
        value: 'desc',
      },
      {
        label: formatProductMessage({ id: 'newest', defaultMessage: 'Newest' }),
        attribute: 'lastModifiedAt',
        value: 'desc',
      },
    ];
  }, [formatProductMessage]);

  const handleOnClick = (attribute: string, value: 'asc' | 'desc', isActiveAttribute: boolean) => {
    if (isActiveAttribute) return;

    replaceSort({ attribute, value });
    if (close) close();
    if (isLoadingState && label) isLoadingState.set((prev) => ({ ...prev, [label]: true }));
  };

  return (
    <div>
      {options.map(({ label, attribute, value }, i) => {
        const isActiveAttribute = attribute === activeSort?.attribute && value === activeSort.value;
        return (
          <div
            key={i}
            className={`p-14 text-14 transition ${
              isActiveAttribute
                ? 'bg-base-accent-1 font-bold text-neutral-5'
                : 'cursor-pointer bg-transparent hover:bg-base-accent-3'
            }`}
            onClick={() => handleOnClick(attribute, value, isActiveAttribute)}
          >
            {label}
          </div>
        );
      })}
    </div>
  );
};

export default SortFacet;
