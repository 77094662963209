import React, { useMemo } from 'react';
import Checkbox from 'components/commercetools-ui/atoms/checkbox';
import { FacetProps } from './types';
import { useProductList } from '../../context';
import { Term, TermFacet } from '../../types';

const TermFacet: React.FC<FacetProps> = ({ attribute, close, isLoadingState, label }) => {
  const { facetsConfiguration, refine } = useProductList();

  const facet = useMemo(() => facetsConfiguration[attribute] as TermFacet, [facetsConfiguration, attribute]);

  const handleOnChange = (term: Term) => {
    if (close) close();
    if (isLoadingState) isLoadingState.set((prev) => ({ ...prev, [label]: true }));
    refine(attribute, term.key);
  };

  return (
    <div className="flex flex-col gap-16">
      {facet.terms.map((term) => (
        <div key={term.identifier} className="flex items-start gap-8 text-14">
          <Checkbox checked={term.selected} onChange={() => handleOnChange(term)} />
          <div>{term.label}</div>
        </div>
      ))}
    </div>
  );
};

export default TermFacet;
