import React from 'react';
import { Product } from 'shared/types/product/Product';
import ProductTile from 'components/commercetools-ui/organisms/product/product-tile';
import useTrack from './useTrack';
import { useProductList } from '../../context';

interface Props {
  products: Product[];
}

const List: React.FC<Props> = ({ products }) => {
  const { searchQuery } = useProductList();

  const { ref, trackViewItem } = useTrack({ products });

  return (
    <div ref={ref} data-test="product-list-view" className="my-20 lg:my-32">
      <div className="grid grid-cols-1 gap-y-4 bg-neutral-5 px-16 pb-8 pt-20 sm:grid-cols-2 sm:gap-y-12 md:mx-24 md:grid-cols-3 md:gap-y-20 lg:mx-20 lg:grid-cols-4 lg:gap-y-32 xl:mx-48">
        {products.map((product) => (
          <ProductTile
            key={product.productId}
            product={product}
            isSearchResult={!!searchQuery}
            onClick={() => {
              trackViewItem(product);
            }}
            className="border-r border-neutral-2 max-sm:border-b max-sm:[&:nth-child(1n)]:border-r-0 sm:max-md:[&:nth-child(2n)]:border-r-0 md:max-lg:[&:nth-child(3n)]:border-r-0 lg:[&:nth-child(4n)]:border-r-0"
          />
        ))}
      </div>
    </div>
  );
};

export default List;
