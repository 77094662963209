import React, { useMemo } from 'react';
import Skeleton from 'react-loading-skeleton';
import { Category } from 'shared/types/product/Category';
import Breadcrumb from 'components/commercetools-ui/atoms/breadcrumb';
import Link from 'components/commercetools-ui/atoms/link';
import Banner from 'components/commercetools-ui/organisms/product/product-list/components/banner';
import { BannerProps } from 'components/commercetools-ui/organisms/product/product-list/types';
import { useFormat } from 'helpers/hooks/useFormat';
import usePath from 'helpers/hooks/usePath';

interface Props {
  categoryId?: string;
  categories: Category[];
  banners: BannerProps[];
}

const Breadcrumbs: React.FC<Props> = ({ categoryId, categories, banners }) => {
  const { formatMessage: formatProductMessage } = useFormat({ name: 'product' });

  const { path } = usePath();

  const ancestorCategories = useMemo(() => {
    if (!path) return [];

    const categoryIdChunks = path.split('?')[0].slice(1).split('/').slice(0, -1);

    return categoryIdChunks.map(
      (id) => (categories.find((category) => [category.categoryId, category.slug].includes(id)) ?? {}) as Category,
    );
  }, [path, categories]);

  const currentCategory = useMemo(() => {
    return (categories.find((category) => category.categoryId === categoryId) ?? {}) as Category;
  }, [categories, categoryId]);

  const parentCategory = useMemo(() => {
    return categories.find((c) => c.categoryId === currentCategory.parentId);
  }, [categories, currentCategory]);

  const subCategories = useMemo(() => {
    return ((categories.find((category) => category.categoryId === categoryId) as Category)?.subCategories.filter(
      (category) => category.productsAmount > 0,
    ) ?? []) as Category[];
  }, [categories, categoryId]);

  const siblingCategories = useMemo(() => {
    return parentCategory?.subCategories.filter((category) => category.productsAmount > 0) ?? [];
  }, [parentCategory]);

  const currentCategoryAncestors = useMemo(() => {
    return ancestorCategories.filter((c) => c.name !== currentCategory.name);
  }, [ancestorCategories, currentCategory]);

  const banner = useMemo(() => {
    if (!banners) return undefined;

    const { key } = currentCategory;
    const currentCategoryBanner = key && banners.find((banner) => [banner.key].includes(key));

    const ancestors = currentCategoryAncestors.map((category) => category.key).reverse();
    const ancestorCategoryBanner = ancestors
      .map((ancestor) => banners.find((banner) => banner.key === ancestor))
      .find((x) => x);

    return currentCategoryBanner ?? ancestorCategoryBanner;
  }, [currentCategoryAncestors, currentCategory, banners]);

  const breadcrumbHeight =
    ancestorCategories.length === 1
      ? `min-h-[165px] sm:min-h-[137px] lg:min-h-[133px]`
      : `min-h-[395px] sm:min-h-[451px] lg:min-h-[447px]`; // Subcategories will display a banner

  if (!categoryId) return <Skeleton className={breadcrumbHeight} />;

  const breadcrumb = (
    <div className={`${banner ? 'pt-0' : 'pt-12'} sm:pt-16`}>
      <Breadcrumb className="leading-none" isBreadcrumb>
        {currentCategoryAncestors.map((category) => (
          <Link key={category.categoryId} link={category.path} className="text-12">
            {category.name}
          </Link>
        ))}

        {currentCategory && (
          <Link key={currentCategory.categoryId} link={currentCategory.path} className="text-12">
            {currentCategory.name}
          </Link>
        )}
      </Breadcrumb>
    </div>
  );

  return (
    <div className="flex flex-col">
      {banner ? <Banner banner={banner}>{breadcrumb}</Banner> : breadcrumb}

      <h1 className="mt-20 px-16 text-24 leading-[35px] md:px-20 lg:mt-16 lg:text-32 xl:px-48">
        {currentCategory.name}
      </h1>

      {/* 1st or 2nd level categories */}
      {subCategories.length > 0 && (
        // The chips have a shadow with 5px spread, so it's necessary to provide extra space on top and bottom,
        // and subtract it from the top margin to keep the same distance from the element above.
        <Breadcrumb className="mt-12 flex h-54" isCategoryNav>
          {/* "All categories" chip */}
          <Link link={currentCategory.path} variant="chip-active">
            {formatProductMessage({ id: 'items.all', defaultMessage: 'All items' })}
          </Link>
          {/* Rest of list */}
          {subCategories.map((category) => (
            <Link key={category.categoryId} link={category.path} variant="chip-inactive">
              {category.name}
            </Link>
          ))}
        </Breadcrumb>
      )}

      {/* Last level categories */}
      {subCategories.length === 0 && siblingCategories.length > 0 && (
        <Breadcrumb className="mt-12 flex h-54" isCategoryNav>
          <Link link={parentCategory?.path} variant="chip-inactive">
            {formatProductMessage({ id: 'items.all', defaultMessage: 'All items' })}
          </Link>
          {siblingCategories.map((category) => (
            <Link
              key={category.categoryId}
              link={category.path}
              variant={`${category.categoryId === currentCategory.categoryId ? 'chip-active' : 'chip-inactive'}`}
            >
              {category.name}
            </Link>
          ))}
        </Breadcrumb>
      )}
    </div>
  );
};

export default Breadcrumbs;
