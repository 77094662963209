import React, { useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'next/navigation';
import LoadingIcon from 'components/commercetools-ui/atoms/button/loadingIcon';
import CloseIcon from 'components/icons/CloseIcon';
import { useFormat } from 'helpers/hooks/useFormat';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { desktop } from 'helpers/utils/screensizes';
import { useProductList } from '../../context';

const CurrentRefinements = () => {
  const [isDesktopSize] = useMediaQuery(desktop);
  const { formatMessage: formatProductMessage } = useFormat({ name: 'product' });

  const { activeRefinements, removeAllRefinements } = useProductList();

  // BEGIN control loading state
  // Control the loading state for active filters and the Clear All button
  // When query params update it means the new content has loaded
  const searchParams = useSearchParams();
  const [isLoading, setIsLoading] = useState<{ [key: string]: boolean }>({});

  const prevSearchParamsRef = useRef(searchParams.toString());
  useEffect(() => {
    const currentSearchParams = searchParams.toString();
    if (currentSearchParams !== prevSearchParamsRef.current) {
      setIsLoading({});
      prevSearchParamsRef.current = currentSearchParams;
    }
  }, [searchParams]);
  // END control loading state

  const clearAllLabel = 'clearAll';

  const handleOnClick = (label: string, callback: () => void) => {
    // If already loading, do nothing (simulate "disabled" state because this is a div, not a button)
    if (isLoading[label]) return;

    setIsLoading((prev) => ({ ...prev, [label]: true }));
    callback();
  };

  if (!activeRefinements.length) return <></>;

  return (
    <div className="mt-12 border-t border-t-neutral-2 pt-12 lg:mt-16 lg:pt-16">
      <div className="flex flex-wrap items-center justify-start gap-14">
        {/* List of active refinements */}
        {activeRefinements.map(({ label, refine }) => (
          <div
            key={label}
            onClick={() => handleOnClick(label, refine)}
            className="flex cursor-pointer items-center rounded-full border border-base-accent-1 bg-white px-12 py-8 text-base-accent-1"
          >
            {isLoading[label] ? (
              <LoadingIcon className="fill-base-accent-1" />
            ) : (
              <>
                <span className="text-14">{label}</span>
                <CloseIcon />
              </>
            )}
          </div>
        ))}

        {/* "Clear all" button, desktop */}
        {isDesktopSize && (
          <div
            className="flex cursor-pointer items-center justify-center gap-4 text-neutral-4"
            onClick={() => handleOnClick(clearAllLabel, removeAllRefinements)}
          >
            {isLoading[clearAllLabel] ? (
              <LoadingIcon className="fill-gray-700" />
            ) : (
              <>
                <span className="text-14">
                  {formatProductMessage({ id: 'clear.all', defaultMessage: 'Clear All' })}
                </span>
                <CloseIcon />
              </>
            )}
          </div>
        )}
      </div>

      {/* "Clear all" button, mobile */}
      {!isDesktopSize && (
        <div
          className="mt-20 flex w-fit cursor-pointer items-center justify-center gap-4 text-neutral-4"
          onClick={removeAllRefinements}
        >
          <span className="text-14">{formatProductMessage({ id: 'clear.all', defaultMessage: 'Clear All' })}</span>
          <CloseIcon />
        </div>
      )}
    </div>
  );
};

export default CurrentRefinements;
