import React from 'react';
import { CategoryDescription } from 'shared/types/product/Category';
import AccordionBtn from 'components/commercetools-ui/atoms/accordion';

interface Props {
  content: CategoryDescription;
}

const Seo: React.FC<Props> = ({ content }) => {
  return content?.name && content?.description ? (
    <div className="mx-auto mb-20 mt-60 max-w-[788px]">
      <AccordionBtn
        className="col-span-2 mt-24 md:mt-0"
        closedSectionTitle={content.name}
        buttonClassName="pb-12 pt-20 border-b border-neutral-2 font-bold text-16 leading-[20px] text-neutral-4 pl-0 md:pr-12 mb-8 md:[&>p]:pl-12"
        panelClassName="pt-14 px-20"
        as="h4"
      >
        <div
          className="font-body text-16 leading-[20px] text-neutral-4"
          dangerouslySetInnerHTML={{ __html: content.description }}
        />
      </AccordionBtn>
    </div>
  ) : null;
};

export default Seo;
