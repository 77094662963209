import React, { ReactNode } from 'react';
import Link from 'components/commercetools-ui/atoms/link';
import { BannerProps } from 'components/commercetools-ui/organisms/product/product-list/types';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { mobile, tablet } from 'helpers/utils/screensizes';
import Image from 'frontastic/lib/image';

interface Props {
  banner: BannerProps;
  children: ReactNode;
}

const Banner: React.FC<Props> = ({ banner, children }) => {
  const [isMinWidthMobile] = useMediaQuery(mobile);
  const [isMinWidthTablet] = useMediaQuery(tablet);
  const image = isMinWidthTablet ? banner.imageDesktop : banner.imageMobile;
  const imageSizeClassName = `relative mx-auto h-[270px] ${isMinWidthTablet ? 'max-w-screen-2xl' : 'max-w-[740px]'}`;

  return (
    <div className="w-full">
      {isMinWidthMobile ? children : null}
      <div className={`w-full bg-background ${!isMinWidthMobile ? 'relative mb-4' : 'mb-16 mt-12'}`}>
        <Link link={banner.link}>
          <div className={imageSizeClassName}>
            <Image {...image} priority loading="eager" fill style={{ objectFit: 'cover' }} />
          </div>
        </Link>
        {!isMinWidthMobile ? <div className="absolute left-0 top-0 w-full pt-12">{children}</div> : null}
      </div>
    </div>
  );
};

export default Banner;
