function FilterIcon({ scale = 1 }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={24 * scale} height={24 * scale} fill="none" viewBox="0 0 24 24">
      <path
        fill="currentColor"
        d="M11.25 20.75v-5.5h1.5v2h8v1.5h-8v2h-1.5zm-8-2v-1.5h5.5v1.5h-5.5zm4-4v-2h-4v-1.5h4v-2h1.5v5.5h-1.5zm4-2v-1.5h9.5v1.5h-9.5zm4-4v-5.5h1.5v2h4v1.5h-4v2h-1.5zm-12-2v-1.5h9.5v1.5h-9.5z"
      ></path>
    </svg>
  );
}

export default FilterIcon;
