import { useCallback, useEffect, useRef } from 'react';
import { useInView } from 'react-intersection-observer';
import { Product } from 'shared/types/product/Product';
import { VIEW_ITEM, VIEW_ITEM_LIST } from 'helpers/constants/events';
import { productToGoogleAnalytics4Item } from 'helpers/utils/mapGoogleAnalytics';

interface Options {
  products: Array<Product>;
}

const useTrack = ({ products }: Options) => {
  const { ref, inView } = useInView({ triggerOnce: true });

  const trackedViewItemList = useRef(false);

  const trackViewItem = useCallback(async (product: Product) => {
    const googleAnalytics4Item = productToGoogleAnalytics4Item(product);
    const currency = product?.variants?.[0]?.price?.currencyCode ?? 'PLN';
    const value = googleAnalytics4Item.price;

    gtag('event', VIEW_ITEM, { currency, value, items: [{ ...googleAnalytics4Item, quantity: 1 }] });
  }, []);

  const trackViewItemList = useCallback(async () => {
    if (inView && !trackedViewItemList.current) {
      const googleAnalytics4Items = products.map((product) => productToGoogleAnalytics4Item(product));

      gtag('event', VIEW_ITEM_LIST, { items: googleAnalytics4Items });

      trackedViewItemList.current = true;
    }
  }, [inView, products]);

  useEffect(() => {
    trackViewItemList();
  }, [trackViewItemList]);

  return { ref, trackViewItem };
};

export default useTrack;
