import React, { Fragment, useEffect, useRef, useState } from 'react';
import Link from 'next/link';
import { usePathname, useSearchParams } from 'next/navigation';
import LoadingIcon from 'components/commercetools-ui/atoms/button/loadingIcon';
import ChevronLeftIcon from 'components/icons/ChevronLeftIcon';
import ChevronRightIcon from 'components/icons/ChevronRightIcon';
import { useProductList } from '../../context';

const Pagination = () => {
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const prevSearchParamsRef = useRef(searchParams.toString());
  const [isLoading, setIsLoading] = useState<{ [key: string]: boolean }>({});
  const { firstPage, lastPage, currentPage, nextPage, previousPage } = useProductList();

  const DISTANCE = 3;
  const pages = [...Array(lastPage)].map((_, i) => i + 1);

  // Control the loading state of the pagination buttons
  // When query params are updated, it means the new page has loaded and we can clear the loading states
  useEffect(() => {
    const currentSearchParams = searchParams.toString();
    if (currentSearchParams !== prevSearchParamsRef.current) {
      setIsLoading({});
      prevSearchParamsRef.current = currentSearchParams;
    }
  }, [searchParams]);

  const getHref = (page: number) => {
    const newSearchParams = new URLSearchParams(searchParams.toString());

    // URL of the first page doesn't have 'page' search param
    if (page === firstPage) newSearchParams.delete('page');
    else newSearchParams.set('page', `${page}`);

    const newQueryString = newSearchParams.toString();

    return `${pathname}${newQueryString ? `?${newQueryString}` : ''}`;
  };

  // Method defined locally because it uses values in the local scope
  const getIntermediatePages = () => {
    let previous = [];
    let next = [];
    let result = [];

    const previousMinIndex = currentPage > firstPage + DISTANCE ? DISTANCE : firstPage;
    previous = pages.slice(Math.max(currentPage - 3, previousMinIndex), currentPage - 1);

    const nextMaxIndex = currentPage < lastPage - DISTANCE ? lastPage - DISTANCE : lastPage - 1;
    next = pages.slice(currentPage, Math.min(currentPage + 2, nextMaxIndex));

    result = [...previous];
    // If currentPage is first or last, don't include it in the "intermediatePages" array because they are already in the DOM
    result =
      currentPage === firstPage || currentPage === lastPage ? [...result, ...next] : [...result, currentPage, ...next];

    return result;
  };

  const intermediatePages = getIntermediatePages();

  // Components defined locally because they use values in the local scope

  const PaginationButtonNumber: React.FC<{ page: number }> = ({ page }) => {
    const isCurrentPage = page === currentPage;
    const label = `${page}`;
    const href = getHref(page);

    return (
      <Link
        href={href}
        className={`flex h-32 w-32 items-center justify-center rounded-full p-4 font-bold ${
          isCurrentPage ? 'bg-base-accent-1 text-neutral-5' : 'hover:bg-base-accent-2'
        } ${isCurrentPage || isLoading[label] ? 'pointer-events-none' : ''}`}
        onClick={() => setIsLoading((prev) => ({ ...prev, [label]: true }))}
      >
        {isLoading[label] ? <LoadingIcon className="fill-base-accent-1" /> : page}
      </Link>
    );
  };

  const PaginationButtonArrow: React.FC<{ side: Side }> = ({ side }) => {
    if (side === 'left' && currentPage === firstPage) return;
    if (side === 'right' && currentPage === lastPage) return;
    const label = side;
    const href = getHref(side === 'left' ? previousPage : nextPage);

    return (
      <Link
        href={href}
        className={`shadow-custom flex h-40 w-40 items-center justify-center rounded-full bg-neutral-5 hover:bg-base-accent-3 ${
          isLoading[label] ? 'pointer-events-none' : ''
        }`}
        onClick={() => setIsLoading((prev) => ({ ...prev, [label]: true }))}
      >
        {isLoading[label] ? (
          <LoadingIcon className="fill-gray-700" />
        ) : side === 'left' ? (
          <ChevronLeftIcon />
        ) : (
          <ChevronRightIcon />
        )}
      </Link>
    );
  };

  const PaginationEllipsis: React.FC<{ side: Side }> = ({ side }) => {
    if (side === 'left' && currentPage <= DISTANCE + 1) return;
    if (side === 'right' && currentPage >= lastPage - DISTANCE) return;
    return <div className="flex cursor-default items-center justify-center">...</div>;
  };

  return (
    <div id="Pagination" className="flex items-center justify-center gap-8 text-neutral-4">
      {/* Previous page arrow button */}
      <PaginationButtonArrow side="left" />

      {/* Grid container to make all numbers and ellipsis have the same size */}
      <div className="grid auto-cols-fr grid-flow-col gap-8">
        {/* First page button */}
        <PaginationButtonNumber page={firstPage} />

        {/* Ellipsis */}
        <PaginationEllipsis side="left" />

        {/* Intermediate pages button */}
        {intermediatePages.map((page) => {
          return (
            <Fragment key={page}>
              <PaginationButtonNumber page={page} />
            </Fragment>
          );
        })}

        {/* Ellipsis */}
        <PaginationEllipsis side="right" />

        {/* Last page button */}
        {firstPage !== lastPage && <PaginationButtonNumber page={lastPage} />}
      </div>

      {/* Next page arrow button */}
      <PaginationButtonArrow side="right" />
    </div>
  );
};

export default Pagination;

type Side = 'left' | 'right';
