import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSearchParams } from 'next/navigation';
import { Menu, Popover } from '@headlessui/react';
import LoadingIcon from 'components/commercetools-ui/atoms/button/loadingIcon';
import ChevronDownIcon from 'components/icons/ChevronDownIcon';
import { useFormat } from 'helpers/hooks/useFormat';
import { useProductList } from '../../context';
import useDynamicFacets from '../../hooks/useDynamicFacets';
import styles from '../../styles/index.module.css';
import SortFacet from '../facets/sort';

export type DesktopFacetsProps = {
  page?: 'PLP' | 'Search';
};
const DesktopFacets: React.FC<DesktopFacetsProps> = ({ page = 'PLP' }) => {
  const { formatMessage: formatProductMessage } = useFormat({ name: 'product' });

  const { facetsConfiguration, totalItems } = useProductList();

  // BEGIN control loading state
  // Control the loading state for Facets and the Sort button
  // When query params update it means the new content has loaded
  const searchParams = useSearchParams();
  const [isLoading, setIsLoading] = useState<{ [key: string]: boolean }>({});
  const isLoadingState = useMemo(() => ({ value: isLoading, set: setIsLoading }), [isLoading]);

  const prevSearchParamsRef = useRef(searchParams.toString());
  useEffect(() => {
    const currentSearchParams = searchParams.toString();
    if (currentSearchParams !== prevSearchParamsRef.current) {
      setIsLoading({});
      prevSearchParamsRef.current = currentSearchParams;
    }
  }, [searchParams]);
  // END control loading state

  const facets = useDynamicFacets({
    configuration: facetsConfiguration,
    render: ({ attribute, Component }) => (
      <Popover as="div" className="relative" key={attribute}>
        {({ open, close }) => {
          const label = facetsConfiguration[attribute].label;
          return (
            <>
              <Popover.Button disabled={isLoading[label]}>
                <div className="flex items-center rounded-full border border-neutral-4 bg-white px-12 py-8">
                  {isLoading[label] ? (
                    <LoadingIcon className="fill-gray-700" />
                  ) : (
                    <>
                      <span className="text-14 text-neutral-4">{label}</span>
                      <ChevronDownIcon />
                    </>
                  )}
                </div>
              </Popover.Button>
              <Popover.Panel
                static
                className={`shadow-custom absolute left-0 max-h-[316px] min-w-[320px] origin-top-right translate-y-[10px] overflow-auto rounded-xl border border-neutral-4 bg-white px-20 py-24 transition ${
                  styles.desktop_facet_container
                } ${open ? 'z-20 scale-100' : 'z-[-1] scale-95 opacity-0'}`}
              >
                {React.cloneElement(Component, {
                  close,
                  isLoadingState,
                  label,
                })}
              </Popover.Panel>
            </>
          );
        }}
      </Popover>
    ),
  });

  const sortFacet = useMemo(
    () => (
      <Menu as="div" className="relative">
        {({ open, close }) => {
          const label = 'sort';
          return (
            <>
              <Menu.Button disabled={isLoading[label]}>
                <div className="flex items-center rounded-full border border-neutral-4 bg-white px-12 py-8">
                  {isLoading[label] ? (
                    <LoadingIcon className="fill-gray-700" />
                  ) : (
                    <>
                      <span className="text-14 text-neutral-4">
                        {formatProductMessage({ id: 'sortBy', defaultMessage: 'Sort by' })}
                      </span>
                      <ChevronDownIcon />
                    </>
                  )}
                </div>
              </Menu.Button>
              <Menu.Items
                static
                className={`shadow-custom absolute right-0 origin-top-right translate-y-[10px] rounded-xl border border-neutral-4 bg-white py-12 shadow-lg transition ${
                  open ? 'z-20 scale-100' : 'z-[-1] scale-95 opacity-0'
                }`}
              >
                <Menu.Item as="div">
                  <SortFacet close={close} isLoadingState={isLoadingState} label={label} />
                </Menu.Item>
              </Menu.Items>
            </>
          );
        }}
      </Menu>
    ),
    [formatProductMessage, isLoading, isLoadingState],
  );

  return (
    <div className={`hidden items-start justify-between lg:flex lg:gap-50 ${page === 'Search' ? 'pt-32' : 'pt-48'}`}>
      <div className="flex flex-1 flex-wrap items-center gap-12">{facets}</div>
      <div className="flex items-center gap-18">
        <span className="text-14 text-neutral-4">
          {totalItems} {formatProductMessage({ id: 'items', defaultMessage: 'Items' })}
        </span>
        <div>{sortFacet}</div>
      </div>
    </div>
  );
};

export default DesktopFacets;
